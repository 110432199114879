<template>
    <div class="v-not-auth container">
        <div class="v-not-auth__text">
            {{$filters.localize('notAuthText')}}.
        </div>
        <div class="v-not-auth__text">
            {{$filters.localize('notAuthCall')}}.
        </div>
    </div>
</template>

<script>
export default {
    beforeCreate() {
		setTimeout(() => document.title = this.$route.meta.title || 'Error', 0)
	},
}
</script>

<style lang="scss">
.v-not-auth
{
    padding-top: 128px;

    &__text
    {
        margin-bottom: 24px;
        @include font-set(13, 1.2em);
    }
}
</style>
